.errorBoundaryPage {
  display: flex;
  flex-direction: column;
  height: 100%;

  .sky {
    flex-grow: 1;
  }

  .water {
    padding-top: 5rem;
    position: relative;
    background-color: var(--text-color);
    min-height: 40vh;
    color: var(--primary-color);
    text-align: center;

    a {
      &:link,
      &:visited {
        color: var(--primary-color);
      }

      svg {
        font-size: 1rem;
      }
    }

    .ship {
      top: -12rem;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      max-width: 18rem;
    }
  }
}
