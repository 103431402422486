@import '../../../styles/variables.scss';

.filter {
  position: relative;
  line-height: initial;
}

.icon {
  width: 1.125rem;
  margin-left: 0.125rem;
  &.searchIcon {
    color: var(--accent-color);
  }

  &.closeIcon {
    color: var(--text-color);
  }

  &.disabled {
    fill: $medium-grey;
  }
}

.button {
  position: absolute;
  background: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0.25rem;
  top: 0;
}

.input {
  padding-right: 1.5rem;
  width: 100%;
  min-width: 5rem;
  &:focus {
    outline: none;
  }
}
