@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/mixins';
@import '../../styles/triangle';

$outside-month-background-color: rgb(228, 228, 228);
$outside-month-text-color: #b3ada7;
$time-picker-width: 110px;
$time-picker-background-color: #f2f2f2;
$day-cell-size: spacing(8);

:global {
  @import 'react-datepicker/dist/react-datepicker';

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container {
    input {
      @include input;
    }
  }

  .react-datepicker {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 0;
    font-family: $font-family-strong;
    filter: drop-shadow(0 $spacing $spacing rgba($black, 0.1));
    background: $white;
  }

  .react-datepicker-popper[data-placement] .react-datepicker__triangle::before {
    content: none;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: $white;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: $white;
  }

  .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle::after {
    border-left-color: $white;
  }

  .react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle::after {
    border-right-color: $white;
  }

  .react-datepicker__header {
    border-bottom: 0;
    padding: spacing(2) 0 $spacing;
    background: none;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: normal;
    color: $very-dark-blue;
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    margin: spacing(0.5) 0;
    border-radius: 0;
    width: $day-cell-size;
    height: $day-cell-size;
    line-height: $day-cell-size;
    color: $very-dark-blue;
  }

  .react-datepicker__day-name {
    font-family: $font-family-regular;
    text-transform: uppercase;
    color: $very-dark-blue;
  }

  .react-datepicker__navigation {
    top: 4px;

    &--next--with-time:not(&--next--with-today-button) {
      right: $time-picker-width;
    }

    ::before {
      border-color: var(--accent-color);
    }

    &:hover {
      ::before {
        border-color: $very-dark-blue;
      }
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
    color: $very-dark-blue;
    background: $white;
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 0;
      color: var(--text-accent-color);
      background: var(--accent-color);
    }

    &--today {
      position: relative;
      &::before {
        $size: 0.25rem;
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0.25rem;
        transform: translateX(-50%);
        border-radius: 50%;
        width: $size;
        height: $size;
        background: $moderate-lime-green;
      }
    }

    &--outside-month,
    &--disabled {
      font-family: $font-family-regular;
      color: $outside-month-text-color;
      background: $outside-month-background-color;
    }

    &--in-range,
    &--in-selecting-range {
      color: var(--text-accent-color-color);

      &,
      &:not(.react-datepicker__day--selected, .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end) {
        background-color: $light-blue;
      }
    }

    &--keyboard-selected {
      position: relative;
      outline: 2px solid var(--accent-color);
      background: none;
    }

    &--selected {
      color: var(--text-accent-color);
      background: var(--accent-color);
    }

    &--selecting-range-start,
    &--selecting-range-end {
      position: relative;

      &,
      &:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        background-color: var(--accent-color);
      }

      &::after {
        content: '';
        position: absolute;
      }
    }

    &--selecting-range-start:not(&--selecting-range-end) {
      &::after {
        @include css-triangle(right, var(--accent-color), $day-cell-size, 4px);
        right: auto;
        left: 100%;
      }
    }

    &--selecting-range-end:not(&--selecting-range-start) {
      &::after {
        @include css-triangle(left, var(--accent-color), $day-cell-size, 4px);
        left: auto;
        right: 100%;
      }
    }
  }

  .react-datepicker__time-container {
    margin-left: spacing(4);
    border-left: 0;
    width: $time-picker-width;
    background: $time-picker-background-color;

    .react-datepicker__time {
      border-radius: 0;
      background: none;

      .react-datepicker__time-box {
        width: $time-picker-width;

        ul.react-datepicker__time-list {
          scrollbar-color: rgba($very-dark-blue, 0.5) rgba($white, 0);
          scrollbar-width: thin;

          li.react-datepicker__time-list-item {
            font-family: $font-family-regular;
            color: $very-dark-blue;

            &,
            &--selected {
              font-weight: normal;
            }

            &--selected {
              font-family: $font-family-strong;
              color: var(--text-color);
            }
          }
        }
      }
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    &:hover {
      background: $light-blue-grey;
    }

    &--selected {
      &,
      &:hover {
        background: var(--accent-color);
      }
    }
  }
}

.datePickerLabel {
  display: block;
  margin-bottom: $spacing;
}
