@import '../../../styles/spacing';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.selectorContainer {
  display: flex;
  align-items: center;
  color: $dark-grey;
  font-family: $font-family-strong;
  font-size: $small-font-size;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-right: spacing(5);
}

.button {
  position: relative;
  color: $dark-grey;
  display: flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
}

.popoverPanel {
  padding: $small-font-size;
  background-color: $white;
  border: 1px solid $medium-light-grey;
  border-radius: $xxs-font-size;
  box-shadow: 0 2px $xxs-font-size $shadow-color;
  position: absolute;
  left: 0;
  z-index: $dropdown-menu-index;
  margin-top: 4px;
  width: 14rem;
}

.selectorOptions {
  display: flex;
  flex-direction: column;
  gap: $xxs-font-size;
  font-family: $font-family-regular;
}

.selectorOption {
  display: flex;
  align-items: center;
}

.radio {
  margin-right: $xxs-font-size;
}

.selectorLabel {
  @include clickable;
  font-size: $small-font-size;
  color: $very-dark-blue;
}
