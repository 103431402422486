@import '../../../../styles/mixins';

.header {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 2.5rem;
}

.backButton {
  width: 100%;
  display: flex;
  padding: 0;
  .backArrow {
    fill: var(--text-color);
    height: 1.5rem;
  }
}
