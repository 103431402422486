@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.legend {
  display: flex;
  padding-top: spacing(3);
  gap: spacing(3);
}

.legendItem {
  --r: 0;
  --g: 0;
  --b: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing;
}

.legendItemIcon {
  flex: 1 1 auto;
  width: 0.75rem;
  height: 0.75rem;
  border: 1px solid;
  border-radius: 50%;
  border-color: rgb(var(--r), var(--g), var(--b));
  background-color: rgba(var(--r), var(--g), var(--b), 0.1);
}

.legendItemText {
  font-size: $xs-font-size;
  font-family: $font-family-regular;
}
