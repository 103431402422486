@use 'sass:math';

@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';
@import '../../styles/triangle.scss';

$control-size: spacing(8);
$panel-arrow-size: spacing(2);

.container {
  position: relative;
}

.open {
  display: block;
}

.button {
  position: relative;
  color: $dark-grey;

  svg {
    color: $dark-grey;
  }

  &:hover,
  &:focus {
    color: $very-dark-blue;
    svg {
      color: $very-dark-blue;
    }
  }
}

.panel {
  position: absolute;
  top: calc(100% + #{$panel-arrow-size});
  right: calc(50% - #{$panel-arrow-size * 2});
  z-index: $table-config-panel-zindex;
  display: flex;
  flex-direction: column;
  gap: $spacing;
  border-radius: $spacing;
  border: 1px solid $light-grey;
  padding: spacing(4);
  width: max-content;
  min-width: 18rem;
  filter: drop-shadow(0 math.div($spacing, 2) $spacing $shadow-color);
  background: $white;

  &.open {
    &::before {
      @include css-triangle(top, $white, $panel-arrow-size, $panel-arrow-size * 2);
      content: '';
      position: absolute;
      right: $panel-arrow-size;
      top: -$panel-arrow-size;
    }
  }
}

.heading {
  margin: 0;
  font-size: $default-font-size;
}

.list {
  display: flex;
  flex-direction: column;
  margin: spacing(2) 0;
  overflow-y: auto;
  max-height: calc(100vh - 320px);
  padding: 0;
}

.item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: $spacing;
}

.itemText {
  justify-self: start;
  display: inline-block;
  font-family: $font-family-regular;
}

.dragHandle {
  padding: 0 $spacing;
  cursor: move;
  color: $medium-grey;

  &:hover,
  &:focus,
  &.isDragging {
    color: var(--accent-color);
  }
}

:global {
  .sortable-chosen {
    > * {
      opacity: 0.7;
    }
  }
}

.resetColumnOrderBtn {
  transition: color $default-easing;
  margin-top: $spacing;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing;
  color: $very-dark-blue;

  &:focus,
  &:hover {
    color: var(--accent-color);
  }
}

.applyBtn {
  padding: spacing(2);
  color: $white;
  background: var(--accent-color);
}
