@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.nominationSections {
  display: grid;
  grid-template-rows: 10rem auto;
  row-gap: spacing(5);

  .timeline {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: spacing(4);

  .actions {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    padding-bottom: spacing(3);

    button {
      font-size: $xs-font-size;
      font-family: $font-family-strong;
      color: var(--accent-color);
      background-color: var(--primary-color);
      padding: spacing(1.5) spacing(2);
      border-radius: 3px;
      border: 1px var(--accent-color) solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 1.25rem;
      height: 2.35rem;

      &.disabled {
        pointer-events: none; 
        opacity: 0.6; 
        cursor: not-allowed; 
      }

      &.saveButton,
      &.poolButton,
      &.updateButton {
        color: var(--primary-color);
        background-color: var(--accent-color);
      }

      &.deleteButton {
        color: var(--primary-color);
        border-color: var(--primary-color);
        background-color: $warning-color;
      }

      .isProcessing {
        @include spinner(0.875rem, $white, rgba($white, 0.3), 0.125rem);
      }
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 16rem;
  padding: spacing(4) spacing(6);
  column-gap: spacing(5);

  &Button {
    transition: $default-easing;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed $medium-grey;
    border-radius: spacing(2);
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-family: $font-family-strong;
    font-size: $large-font-size;
    font-style: italic;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    svg {
      display: block;
      margin: spacing(2);
      width: 2.75rem;
      height: 2.75rem;
      color: var(--accent-color);
    }

    &:focus,
    &:hover {
      border-style: solid;
      border-color: var(--accent-color);
      background: $light-blue-grey;
    }
  }
}
