@import '../../../styles/mixins';
@import '../../../styles/variables';

.sideMenuItem {
  @include clickable;

  padding: 1.25rem 0.75rem;
  font-family: $font-family-regular;
  font-size: $small-font-size;
  text-align: left;
  text-decoration: none;
  width: 100%;
}
.sideMenuItemText {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: $small-font-size;
  font-family: $font-family-strong;
  user-select: none;
  margin-right: 1em;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: var(--accent-color);
  }
}
.sideMenuItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
