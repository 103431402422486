@use 'sass:math';

// CSS Triangles Mixin
@mixin css-triangle($direction, $color, $height, $width) {
  // Base styles for all triangles
  border-style: solid;
  height: 0;
  width: 0;
  // Cardinal Directions - can't be scalene this way
  @if ($direction == 'top') {
    border-color: transparent transparent $color transparent;
    border-width: 0 math.div($width, 2) $height math.div($width, 2);
  }
  @if ($direction == 'bottom') {
    border-color: $color transparent transparent transparent;
    border-width: $height math.div($width, 2) 0 math.div($width, 2);
  }
  @if ($direction == 'right') {
    border-color: transparent transparent transparent $color;
    border-width: math.div($height, 2) 0 math.div($height, 2) $width;
  }
  @if ($direction == 'left') {
    border-color: transparent $color transparent transparent;
    border-width: math.div($height, 2) $width math.div($height, 2) 0;
  }
  // Ordinal Directions - can be scalene this way!
  @if ($direction == 'top-left') {
    border-color: $color transparent transparent transparent;
    border-width: $height $width 0 0;
  }
  @if ($direction == 'top-right') {
    border-color: transparent $color transparent transparent;
    border-width: 0 $width $height 0;
  }
  @if ($direction == 'bottom-left') {
    border-color: transparent transparent transparent $color;
    border-width: $height 0 0 $width;
  }
  @if ($direction == 'bottom-right') {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $height $width;
  }
}
