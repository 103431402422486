@import '../../styles/variables.scss';

.pin {
  color: rgba(0,0,0,0.12);
  &.pinned {
    color: var(--accent-color);
    &:hover {
      color: var(--accent-color);
    }
  }
  &:hover {
    color: rgba($cerulean, 0.35);
  }
}
