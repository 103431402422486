@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.layout {
  height: 100vh;
  display: flex;

  .splash {
    flex-grow: 2;
    background-image: url('../../../public/assets/splash.jpg');
    background-size: cover;
    mix-blend-mode: multiply;
  }

  .title {
    font-family: $font-family-strong;
    font-weight: normal;
    font-size: $xl-font-size;
    letter-spacing: -0.0875rem;
  }

  .main {
    width: 30rem;
    padding: 4rem 4rem 2rem;
    overflow-y: auto;

    .header {
      display: flex;
      text-decoration: none;
      &:hover,
      &:visited {
        color: $very-dark-blue;
      }

      .logo {
        width: 50px;
        height: auto;
        align-self: center;
      }

      .title {
        padding-left: 0.5rem;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;

    .input {
      padding: 0.5rem;
      border: 1px solid lightgray;

      &.hasError {
        border-color: $warning-color;
      }
      & + .input {
        margin-top: 1rem;
      }

      & + .errorNotification {
        font-size: $xs-font-size;
        color: $very-dark-blue;
        margin-top: 0.25rem;
        .note {
          color: $warning-color;
        }
        & + .input {
          margin-top: 1rem;
        }
      }
    }

    .button {
      font-family: $font-family-strong;
      margin-top: 1rem;
      border: none;
      padding: 0.5rem;
      color: white;
      text-transform: uppercase;
    }

    .submit,
    .loading {
      background-color: $very-dark-blue;
    }

    .ssoLogin {
      background-color: $dark-red;
    }

    .loading {
      display: flex;
      align-items: center;

      .loader {
        @include spinner(1rem, white, rgba(white, 0.3));
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.5rem;
      }
    }

    .forgotPassword {
      margin-top: 1rem;
      font-size: $xs-font-size;
      color: $dark-red;
    }

    .clarificationText {
      margin-top: 1rem;
      font-size: 0.75rem;
    }
  }
}
