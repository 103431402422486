@import '../../styles/mixins';
@import '../../styles/spaced';
@import '../../styles/variables';

.container {
  padding: 1.25rem;
}

.heading {
  font-size: $xl-font-size;
}

.link {
  color: var(--text-color);
}
