@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.tableContainer {
  overflow: auto;
  height: 100%;
  background-color: var(--primary-color);
  margin-top: 10px;
}

.table {
  thead {
    position: sticky;
    top: 0;
    background-color: var(--primary-color);
    z-index: $table-header-index;
  }

  .groupHeader {
    background: yellow;
    border-top: 2px black solid;
    font-family: $font-family-strong;

    .element {
      position: relative;
      float: left;

      &+.element {
        padding-left: spacing(2);
        border-left: 1px var(--primary-color) solid;
        margin-left: spacing(2);
      }
    }

    &.nominatedGroupHeader {
      $bg-color: #999;
      color: var(--primary-color);
      border-top: 2px $bg-color solid;
      background-color: $bg-color;
    }
  }

  .actionCell {
    width: 4rem;
  }
}

.tableActions {
  display: flex;
  gap: spacing(2);
  align-items: center;

  &.nominations {
    align-self: flex-end;
  }
}

.pin {
  color: $medium-light-grey;

  &.isPinned {
    color: var(--accent-color);
  }
}

.isAtRisk {
  >svg {
    fill: $yellow;
    font-size: 1rem;
  }
}

.icons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.isDerived {
  background-color: var(--selected-background-color);
}

.nominationTable {
  table-layout: fixed;
}

.actionCell {
  width: 3.5rem;
}

.nameCell {
  width: 4.5rem;
}

.cell {
  width: 6rem;
}

.tablePinCell button {
  padding: 0;
}

.isAtRiskCell {
  width: 4.5rem;
}

.twoGoals {
  background-color: rgba(#007e91, 0.1);
}

.threeGoals {
  background-color: rgba(#007e91, 0.2);
}

.fourGoals {
  background-color: rgba(#007e91, 0.3);
}

.tboCell {
  width: 3.5rem;
}

.hullTypeCell,
.locationCell {
  width: 5.5rem;
}

.tboIcon {
  opacity: 0.2;

  &:hover {
    cursor: pointer;
  }

  &.hasTBO {
    opacity: 0.8;
  }

  &.hasReceivedTBO {
    opacity: 1;
    color: #f39900;
  }
}

.available {
  color: $moderate-lime-green;
}

.notAvailable {
  color: $warning-color;
}

.inOtherNominations {
  opacity: 0.2;

  &:hover {
    cursor: pointer;
  }

  &.hasOtherNominations {
    opacity: 0.8;
  }
}

.isUpdated {
  background-color: rgba($moderate-lime-green, 0.1);

  &.isOdd {
    background-color: rgba($moderate-lime-green, 0.2);
  }
}

.color-hot {
  background-color: rgba($magenta, 0.1);
}

.color-empty {
  background-color: rgba($light-blue, 0.14);

  &.color-Odd {
    background-color: rgba($light-blue, 0.23);
  }
}

.color-oh-highlight {
  background-color: rgba(50, 168, 225, 0.4);
}

.color-lmr-highlight {
  background-color: rgba(50, 220, 0, 0.4);  
}

.color-none {
  background-color: transparent;
}
