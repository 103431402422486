@import '../../../styles/spacing';

.button {
  position: fixed;
  bottom: spacing(2);
  background-color: var(--primary-color);
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
