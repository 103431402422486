@import './variables';

:root {
  --primary-color: white;
  --background-color: #{$light-grey};
  --selected-background-color: #{$very-light-blue};
  --border-color: #{$light-grey};
  --text-color: #{$very-dark-blue};
  --accent-color: #{$cerulean};
  --text-accent-color: #{$white};
  --input-color: #{$dark-grey};
}
