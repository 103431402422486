@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';

.column {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sortArrow {
    height: 1.125rem;
    fill: $medium-grey;
    transform: rotate(-90deg);

    &.active {
      fill: var(--accent-color);
    }

    &.desc {
      transform: rotate(90deg);
    }
  }
}
