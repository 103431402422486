@import '../../../styles/spacing';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.primaryMenu {
  width: 100%;
  height: $header-height;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  .menuText {
    font-family: $font-family-strong;
    font-size: $xs-font-size;

    text-transform: uppercase;
    pointer-events: none;
    color: var(--text-color);
  }

  .menuItem {
    @include clickable();

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 50px;
    appearance: none;
    outline: none;
    border-right: solid 1px var(--background-color);

    .icon {
      color: var(--text-color);
      font-size: 22px;
    }

    &.toggleItem {
      padding: 0 spacing(4);

      .label {
        padding-left: $spacing;
        font-family: $font-family-strong;
        font-size: $xs-font-size;
        color: var(--text-color);
      }
    }
  }

  .logoItem {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 spacing(2);
  }

  .right {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: auto;

    .menuItem {
      border-right: 0;
      border-left: solid 1px var(--background-color);
    }
  }

  .bargePoolUpdateBlock {
    display: flex;
    height: 100%;

    .poolRefresh {
      font-size: 0.875rem;
      padding: spacing(2) 0;
      margin-right: spacing(1.5);
      color: $medium-grey;
      transition: color $default-easing;

      &:hover {
        color: $dark-grey;
      }

      svg {
        font-size: 1.5rem;
      }
    }
  }
}
