@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/triangle.scss';
@import '../../../styles/mixins.scss';

$col-width: 16.5rem;
$bottom-space: 40px;

.panel {
  position: fixed;
  z-index: $modal-index;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.isOverflowHidden {
    overflow: hidden;
  }
}

.footer {
  display: grid;
  grid-template-columns: $col-width spacing(10) $col-width spacing(10) $col-width spacing(10) $col-width spacing(10) $col-width;
  padding: spacing(4) 0;

  button {
    font-size: $small-font-size;
    font-family: $font-family-strong;
    padding: spacing(1) spacing(2);
    grid-column: 9/9;
    color: var(--primary-color);
    background-color: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      background-color: $disabled-grey;
      cursor: not-allowed;
    }

    .spinner::before {
      content: '';
      margin-right: $spacing;
      @include spinner(0.75rem, $white, rgba($white, 0.3), 0.125rem);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  background: var(--primary-color);
  padding: spacing(2) spacing(6) 0;
  transition: 0.2s transform;
  &.isCollapsed {
    transform: translateY(calc(100vh - 114px));
  }
}

.bargePool {
  position: absolute;
  left: 0;
  top: 4.5rem;
  bottom: $bottom-space;
  width: 100%;
  background-color: var(--background-color);
  z-index: -1;
}

.columns {
  flex-grow: 1;
  display: grid;
  grid-template-columns: $col-width spacing(10) $col-width spacing(10) $col-width spacing(10) $col-width spacing(10) $col-width;
  grid-auto-flow: column;
  hr {
    width: 1px;
    margin: 0;
    padding-left: spacing(5);
    border: none;
    border-right: var(--border-color) 1px solid;
  }
}

.title {
  flex-shrink: 0;
  margin: 0;
  font-size: $xl-font-size;
  padding: spacing(6) spacing(6) spacing(2);
}

.closeButton {
  $size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: $size;
  height: $size;
}

.statsContainer {
  position: relative;
  overflow: hidden;
}

.statsContent {
  width: 200%;
  position: absolute;
  transition: 0.2s transform;
  display: grid;
  grid-template-columns: 50% 50%;

  .pinnedBargesSection {
    .sectionTitle {
      padding-left: $spacing;
      font-size: $small-font-size;
      font-family: $font-family-strong;
    }
    .backButton {
      padding: 0;
      color: var(--accent-color);
    }

    .editPreselectedBarges {
      margin-top: spacing(3);
    }
  }

  .stats {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      font-size: $small-font-size;

      .bigNr {
        font-family: $font-family-strong;
        font-size: $xxl-font-size;
        line-height: 1;
      }

      & + li {
        padding-top: spacing(3);
      }

      .bargePoolToggle {
        font-family: $font-family-strong;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--text-color);

        text-decoration: underline;

        svg {
          margin-right: $spacing;
        }

        &::after {
          content: '';
          @include css-triangle('top', var(--accent-color), 0.35rem, 0.7rem);
        }

        &.isActive {
          color: var(--accent-color);
          &::after {
            @include css-triangle('bottom', var(--accent-color), 0.35rem, 0.7rem);
          }
        }
      }

      &.pinnedBarges {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .count,
        .actions {
          display: flex;
          flex-direction: column;
        }
        .actions {
          gap: $spacing;

          &.pinned button {
            color: var(--accent-color);

            &:disabled {
              color: $disabled-grey;
            }
          }

          &.excluded button {
            color: rgb(191, 75, 64);

            &:disabled {
              color: $disabled-grey;
            }
          }
        }
      }
    }

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.isFetching {
      color: $disabled-grey;
    }

    &.isDisabled {
      li {
        color: $disabled-grey;
        .bargePoolToggle {
          pointer-events: none;
          color: $disabled-grey;
          &::after {
            content: '';
            @include css-triangle('top', $disabled-grey, 0.35rem, 0.7rem);
          }
        }
      }
    }
  }
  &.isPinnedBargesVisible {
    transform: translateX(-50%);
  }
}

.form {
  display: flex;
  flex-direction: column;

  .formTitle {
    margin: 0;
    font-size: $small-font-size;
    padding-bottom: spacing(3);
  }

  &.isDisabled > * {
    pointer-events: none;
    color: $disabled-grey;
  }
}

.warning {
  &Box {
    margin-top: spacing(4);
  }

  &Message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: spacing(2);
    white-space: pre-wrap;

    .exportButton {
      font-family: $font-family-strong;
      padding: $spacing spacing(2);
      color: var(--primary-color);
      border: 1px var(--primary-color) solid;
      &:hover {
        background-color: var(--primary-color);
        color: $warning-color;
      }
    }
  }
}
