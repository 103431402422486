@import '../../../../styles/variables';
@import '../../../../styles/spacing';
@import '../../../../styles/mixins';

.label {
  font-size: $small-font-size;
  font-weight: 600;
  margin-bottom: $xxs-font-size;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(2);
  width: 100%;
}

.draught {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.draughtEntry {
  display: flex;
  align-items: center;
  margin-bottom: spacing(1);
  gap: spacing(2);

  :first-child {
    width: 2rem;
  }

  .nr {
    max-width: spacing(13);
    flex-grow: 1;
    margin-left: $spacing;
    padding: $spacing spacing(2);
    border: var(--border-color) 1px solid;

    &::placeholder {
      color: $disabled-grey;
      opacity: 1;
    }
  }

  .readOnly {
    opacity: 0.5;
    pointer-events: none;
  }

  .clear {
    @include clickable;
    margin: auto 0.5rem;
    color: var(--accent-color);

    &:hover {
      color: $very-dark-blue;
    }

    &.isDisabled {
      color: $disabled-grey;
    }
  }
}

.flexPoolFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: spacing(1);
  margin-bottom: spacing(1);
  width: 100%;
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-top: spacing(2);
}

.iconContainer {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.icon {
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  color: #6c757d;
}

.icon:hover {
  transform: scale(1.2);
}

.active {
  color: $cerulean;
}

.inactive {
  color: $medium-grey;
}

.disabledEntry,
.disabledLabel {
  opacity: 0.5;
  pointer-events: none;
}

.disabledInput,
.readOnly {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.includeTboContainer {
  display: flex;
  flex-direction: column;
  margin-top: spacing(3);
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: spacing(2);
}

.tboInput {
  flex-grow: 1;
  padding: $spacing (2);
  border: 1px solid $medium-light-grey;
  height: spacing(6);
}

.addButton {
  padding: spacing(1) spacing(2);
  background-color: $cerulean;
  color: $white;
  border: none;
  cursor: pointer;
  margin-left: -5px;
  border-radius: 0 4px 4px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $very-dark-blue;
  }

  &.isDisabled {
    color: $disabled-grey;
  }
}

.flexPoolFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: spacing(1);
  margin-bottom: spacing(1);
  width: 100%;
}

.tboList {
  margin-top: spacing(2);
  max-height: spacing(8);
  overflow-y: auto;
}

.tboItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: spacing(1);
  background-color: $light-grey;
  border-radius: 4px;
  margin-top: spacing(1);
}

.removeButton {
  @include clickable;
  color: $warning-color;
  border: none;
  border-radius: 4px;
  padding: spacing(1);
  display: flex;
}

.noTbo {
  color: $disabled-grey;
  font-size: $small-font-size;
}

.errorMessage {
  font-size: $small-font-size;
  margin-top: spacing(2);
}
