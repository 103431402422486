@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.listing {
  padding-top: spacing(2);
}

.tableTotals {
  display: flex;
  font-size: $xs-font-size;
  align-items: center;

  .element {
    padding-right: $spacing;

    & + .element {
      padding-left: $spacing;
      border-left: 1px var(--text-color) solid;
    }
  }
}

.selectors {
    display: flex;
    gap: spacing(5); 
    margin-right: spacing(5);
}
