@import '../../../styles/variables';
@import '../../../styles/spacing';

.createNomination {
  display: grid;
  grid-template-columns: 1fr 16rem;
  padding: spacing(4) spacing(6);
  column-gap: spacing(5);
  height: 100%;

  &Button {
    transition: $default-easing;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed $medium-grey;
    border-radius: spacing(2);
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-family: $font-family-strong;
    font-size: $large-font-size;
    font-style: italic;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    svg {
      display: block;
      margin: spacing(2);
      width: 2.75rem;
      height: 2.75rem;
      color: var(--accent-color);
    }

    &:focus,
    &:hover {
      border-style: solid;
      border-color: var(--accent-color);
      background: $light-blue-grey;
    }
  }
}
