.ticker {
  overflow: hidden;
  display: flex;
  align-items: center;

  .inner {
    display: inline-block;
    white-space: nowrap;
    transition: 0.5s transform;

    &.isOverflowing {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.isActive {
      overflow: visible;
    }
  }
}
