@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';

.nominationSummary {
  padding: spacing(3);
  background-color: var(--primary-color);
  font-family: $font-family-strong;

  .title {
    font-size: $xs-font-size;
    margin: 0;
    text-transform: uppercase;
  }

  .info {
    font-size: $xxs-font-size;
    padding-top: spacing(4);
    display: grid;
    grid-template-columns: 6rem auto;
    row-gap: 0.0625rem;

    .label {
      display: flex;
      justify-content: space-between;
    }

    .value {
      padding-left: spacing(2);
      color: var(--accent-color);

      &.tboSubmissionSuccess {
        color: $moderate-lime-green;
      }

      &.tboSubmissionFailure {
        color: $warning-color;
      }
    }

    .spacer {
      grid-column: 1 / 3;
      margin: 0;
      height: 1em;
      border: none;
    }

    .bigNumber {
      font-size: $default-font-size;
      color: var(--accent-color);
      padding: spacing(2) 0;
      margin: 0;
      grid-column: 1 / 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;

      text-align: center;
      .number {
        font-size: 6rem;
      }
    }
  }
}

.tile {
  padding: spacing(3);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  aspect-ratio: 3/2 auto;

  font-family: $font-family-strong;

  .header {
    margin: 0;
    text-transform: uppercase;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .number {
      font-size: 5rem;
      line-height: 1;
    }
  }
}

.scoreContainer {
  position: relative;

  .popoverButton {
    top: 0.875rem;
    right: 1.25rem;
    position: absolute;

    .popover {
      align-self: start;
      font-size: $small-font-size;
      button {
        display: flex;
        padding: 0;
      }

      h3 {
        white-space: nowrap;
        font-size: $small-font-size;
      }
    }
  }

  .score {
    margin: 0;
    font-size: $default-font-size;
    text-align: center;
    .decimal {
      font-size: 2rem;
    }
  }
}

.stats {
  display: flex;
  flex-direction: column;
  gap: spacing(3);

  &Entry {
    font-family: $font-family-strong;
    color: var(--accent-color);
    display: grid;
    grid-template-columns: auto 6rem;
    background-color: var(--primary-color);
    padding: spacing(2) spacing(3);
    align-items: center;
    row-gap: spacing(2);
  }

  &Label {
    font-size: $small-font-size;
    text-transform: uppercase;
  }

  &Value {
    font-size: 1.75rem;
    text-align: right;
  }
}

.tbnBargesSummary {
  background-color: $warning-color;   
  padding:0.945rem;
  color: $white;
}

.tbnBargesTitle {
  color: $white;
  font-size: 0.875rem;
  margin: auto;
  margin-bottom: 24px;
}

.tbnBargesLabel {
  display: grid;
  grid-template-columns: auto 1fr; 
  justify-items: end;
  align-content: center;
  gap: 0.945rem; 
  font-size: var(--small-font-size);
  font-weight: bold;
  font-size:$small-font-size;
}

.tbnBargesInfo {
  display: grid;
  grid-template-rows: auto auto auto; 
  gap: 10px; 
}

.tbnBargesLoadCounts {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  text-align: center;
}


.tbnBargesValue {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.75rem;
  font-family: $font-family-strong;
  text-align: right;
}

.subText {
  display: block;
  font-size: 0.75em;
  color: rgb(165, 165, 165);
  margin-top: 10px;
}
