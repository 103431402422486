@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/spacing.scss';
@import '../../styles/triangle.scss';

.menu {
  position: relative;
  height: 100%;
}

.menuButton {
  @include clickable;
  font-size: $small-font-size;
  font-family: $font-family-regular;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:disabled {
    opacity: 0.35;
  }
  &Label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .clear {
      color: var(--accent-color);
      &:hover {
        color: $very-dark-blue;
      }
    }
  }

  &::after {
    content: '';
    margin-left: $spacing;
  }

  &[data-headlessui-state='']::after {
    @include css-triangle('bottom', var(--accent-color), 0.35rem, 0.7rem);
  }

  &[data-headlessui-state='']:disabled::after,
  &[data-headlessui-state=''][disabled='']::after {
    @include css-triangle('bottom', $disabled-grey, 0.35rem, 0.7rem);
  }

  &[data-headlessui-state='open']::after {
    @include css-triangle('top', var(--accent-color), 0.35rem, 0.7rem);
  }
}

.menuPanel {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  border: 1px var(--border-color) solid;
  z-index: $dropdown-menu-index;
}

.menuItem {
  @include clickable;
  font-size: $small-font-size;

  &[data-headlessui-state='active'] {
    background-color: $light-blue-grey;
  }

  &[data-headlessui-state='disabled'] {
    color: $grayish-orange;
  }
}
