@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.navigationDrawer {
  $menu-width: 335px;
  overflow: hidden;

  position: absolute;
  top: $header-height;
  bottom: 0;
  left: 0;
  height: calc(100% - #{$header-height});
  width: $menu-width;
  max-width: 100%;
  background-color: var(--primary-color);
  border-top: solid 1px var(--border-color);
  border-right: solid 1px var(--border-color);
  z-index: $account-menu-index;
  transform: translateX(-100%);
  transition: 0.2s transform;
  color: var(--text-color);

  &Open {
    transform: translateX(0);
  }

  &Overlay {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    z-index: $account-menu-overlay-index;
    background-color: $shadow-color;
  }

  .entry {
    border-bottom: solid 1px var(--border-color);
    padding: 0;
    display: flex;

    a {
      color: inherit;
      cursor: pointer;

      .text {
        pointer-events: none;
      }
    }

    .inner,
    &.inner {
      @include clickable();

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.25rem 0.75rem;
      color: inherit;
      text-decoration: none;
    }

    .text {
      font-family: $font-family-strong;
      font-size: $small-font-size;
      padding: 1.25rem 0.75rem;
    }

    &Small {
      .text {
        font-size: $xs-font-size;
        padding: 0.5rem 0.75rem;
      }

      .inner,
      &.inner {
        padding: 0.5rem 0.75rem;
      }
    }

    &Inactive {
      background-color: var(--tertiary-color);

      .inner,
      &.inner {
        cursor: default;
      }

      .text {
        opacity: 0.6;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &Top {
      margin-bottom: auto;

      .sectionElement {
        .linkItem {
          padding: 0 0.75rem;
          border-bottom: solid 1px var(--border-color);

          .navigationItem {
            font-family: $font-family-regular;

            .itinerary {
              padding-left: $spacing;
              font-size: $xxs-font-size;
              font-family: $font-family-strong;
            }
          }
        }

        .header {
          margin: 0;
          padding: 1.25rem 0.75rem;
          border-bottom: solid 1px var(--border-color);
          font-size: $default-font-size;
        }

        .createNomination {
          padding: 1.25rem 0.75rem 2rem;

          &Button {
            color: var(--primary-color);
            background-color: var(--accent-color);
            padding: 0 spacing(2) 0 $spacing;
            gap: $spacing;
            font-family: $font-family-strong;
            height: 30px;
            border-radius: spacing(3);
            font-size: $small-font-size;
            display: flex;
            align-items: center;

            >svg {
              color: inherit;
            }
          }
        }
      }
    }

    &Bottom {
      margin-top: auto;

      font-family: $font-family-strong;

      border-top: solid 1px var(--border-color);

      .logoutEntry {
        padding: spacing(2) 0 spacing(4);
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .logout {
        padding: spacing(2);
        display: flex;
        font-family: $font-family-regular;
        font-size: $small-font-size;
        color: var(--text-color);
        display: flex;
      }
    }
  }
}