@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/spacing.scss';

.formRow {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-bottom: spacing(2);
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  font-size: $small-font-size;

  .label {
    font-size: $small-font-size;
    font-weight: 600;
    margin-bottom: $xxs-font-size;
    margin-top: spacing(2);
  }

  .title {
    margin: 0;
    font-family: $font-family-strong;
    font-size: $default-font-size;
  }
}

.hotBargeToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: spacing(6);
  margin-bottom: spacing(1);

  .toggleLabel {
    padding-left: spacing(2);
  }
}

.towConfigItemHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(2);
  padding-bottom: $spacing * 2;

  .section + & {
    margin-top: spacing(4);
    border-top: 1px solid $medium-light-grey;
    padding-top: spacing(2);
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  padding-top: spacing(2);
  padding-bottom: spacing(4);

  .tab {
    font-size: $small-font-size;
    font-family: $font-family-strong;
    padding: $spacing spacing(2);
    background-color: var(--background-color);
    color: $dark-grey;
    display: flex;
    align-items: center;
    line-height: 1.25rem;
    border: 1px transparent dashed;

    .removeTowButton {
      padding: 0 0 0 $spacing;
      display: inline-block;
      color: $dark-grey;

      > svg {
        height: 1rem;
        width: 1rem;
      }
    }

    &:hover,
    &.isActive {
      background-color: var(--text-color);
      color: var(--primary-color);

      .removeTowButton {
        color: var(--primary-color);
      }
    }

    &.hasWarning {
      border-color: $warning-color;
    }
  }

  &.isDisabled {
    opacity: 0.65;

    .tab {
      background-color: $disabled-grey;
    }
  }
}

.addTowButton svg {
  color: var(--accent-color);
}

.addTowButton:disabled svg {
  color: $disabled-grey;
}

.combobox {
  &Input {
    padding: 0;
    border: 1px solid var(--background-color);

    label {
      width: 100%;
      padding-right: $spacing;

      .comboboxOption {
        padding: spacing(2);
        color: var(--text-color);

        &::placeholder {
          color: var(--text-color);
          opacity: 1;
        }
      }
    }
  }

  &Option {
    padding: spacing(2);
    font-family: $font-family-regular;

    .boatId {
      min-width: 4rem;
      font-family: $font-family-strong;
    }
  }
}

.dropdown {
  &Button {
    width: 100%;
    border: 1px solid var(--background-color);
    padding: spacing(2);
  }

  &Item {
    padding: spacing(2);
  }

  &Menu {
    width: 100%;
    font-family: $font-family-strong;
    position: absolute;
    top: 100%;
    z-index: 1;
  }
}

.popover {
  .panelWrapper {
    margin-right: -15px;
  }

  .panel {
    text-align: left;

    h3 {
      margin: 0;
      font-size: $small-font-size;
    }

    p {
      margin: spacing(2) 0 0 0;
      white-space: pre-line;
    }
  }
}

table.matrix {
  width: 100%;
  box-sizing: border-box;
  font-size: $xs-font-size;
  border-collapse: collapse;

  thead {
    font-family: $font-family-strong;
  }

  td {
    padding: $spacing 0;

    &:first-child {
      width: 4rem;
    }
  }

  tbody {
    font-family: $font-family-medium;

    tr {
      border-top: 1px solid var(--background-color);
    }
  }
}

.formTitle {
  margin: 0;
  font-size: $small-font-size;
  padding-left: spacing(2);
}

.numberOfStringsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: spacing(2);

  .inputContainer {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 1.25rem;
    gap: spacing(3);
  }

  .clear {
    @include clickable;
    margin: auto 0.5rem;
    color: var(--accent-color);

    &:hover {
      color: $very-dark-blue;
    }

    &.isDisabled {
      color: $disabled-grey;
    }
  }
}

.goalBlock {
  margin-top: spacing(2);
}
