@import './NLRTM-font-family';
@import './variables';
@import './a11y';
@import './material-ui.module';

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;

  font-family: $font-family-regular;
  font-size: $default-font-size;
  font-weight: normal;
  color: var(--text-color);
  background-color: var(--background-color);
}

body {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-strong;
  font-weight: normal;
}

input,
textarea,
select,
button {
  font-weight: normal;
  background: transparent;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
}

strong,
th {
  font-weight: normal;
  font-family: $font-family-strong;
}

:global(#root) {
  height: 100vh;
  background-image: url(/assets/bitmap.png);
  background-size: 36px 36px;
}
table {
  border-spacing: 0;
  border: 0;
}

thead,
tbody,
tfoot,
th,
td {
  border: 0;
  padding: 0;
}

button {
  font-family: inherit;
  font-size: inherit;
}
