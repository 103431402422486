@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/spacing.scss';

.menu {
  position: relative;
  width: 100%;

  font-size: $small-font-size;

  .entryCode {
    font-size: $xs-font-size;
    color: rgba($very-dark-blue, 0.65);
  }

  .comboboxInput {
    label {
      flex-grow: 1;
    }

    .clear {
      @include clickable;
    }
  }

  &Panel {
    max-height: calc(100vh - ($header-height + spacing(6)));
    overflow-y: auto;
  }

  &Header {
    font-family: $font-family-strong;
    padding: spacing(2);
    color: var(--accent-color);
  }

  &Item {
    padding: spacing(2);
    align-items: center;
    gap: $spacing;

    .icon {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }

    .hub {
      background-image: url('/assets/ingram-icon.png');
    }

    .entryCode {
      width: 4.25rem;
      flex-shrink: 0;
    }
  }
}

.disabled{
  opacity: 0.5;
  pointer-events: none;
}
