@import '../../styles/variables.scss';

.messageBox {
  font-size: $xs-font-size;
  position: relative;
  color: white;
  display: flex;
  padding: 1rem 2rem 1rem 0.75rem;
  border-radius: 3px;
  margin-bottom: 1rem;

  .messageTitle {
    font-family: $font-family-strong;
  }

  .description {
    margin: 0;
  }

  .closeButton {
    border: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .closeIcon {
    fill: white;
    width: 1rem;
    height: 1rem;
  }

  &.success {
    background-color: $moderate-lime-green;
  }

  &.warning {
    background-color: $warning-color;
  }
}
