@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

$default-size: 1.25rem;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultContainer {
  min-height: $default-size;
  gap: spacing(3);
}

.numberedRange {
  display: flex;
  align-items: center;

  flex-grow: 1;
  .number {
    font-family: $font-family-regular;

    &:last-child {
      padding-left: spacing(2);
    }
  }

  .numberedInput {
    border: 1px $light-grey solid;
    padding: $spacing;
    background-color: var(--primary-color);
    font-family: $font-family-strong;
    color: inherit;
    width: 4rem;
  }

  .numberedInput::placeholder {
    color: $disabled-grey;
  }

  &.isDisabled {
    color: $disabled-grey;
    .numberedInput {
      color: $disabled-grey;
    }
  }
}
