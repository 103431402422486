@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.container {
  display: grid;
  padding: spacing(4) spacing(6);
  column-gap: spacing(5);
  min-height: 100%;
}

.section {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  padding: spacing(4);
  background-color: var(--primary-color);
  min-height: 100%;
  height: fit-content;

  .menu {
    display: flex;
    gap: spacing(1);
    align-items: baseline;
  }

  .filter {
    width: 10rem;
    font-size: 0.8rem;
  }

  .nominations {
    display: grid;
    min-height: 100%;

    .centeredSpinner {
      position: relative;
      left: 0;
      top: 50%;
    }

    .emptyList {
      position: relative;
      left: 0;
      top: 10%;
      color: $dark-grey;
      text-align: center;
    }
  }
}

.nomination {
  @include clickable;
  padding: spacing(1.5) spacing(2);
  margin-bottom: spacing(2);
  border: solid 1px var(--background-color);
  width: 100%;
  height: fit-content;

  &.imported:hover {
    background-color: rgba($orange, 0.15);
    transition: background-color 0.3s ease;
  }

  &:hover {
    background-color: rgba($light-blue, 0.15);
    transition: background-color 0.3s ease;
  }

  .label {
    display: flex;
    justify-content: space-between;
  }

  .title {
    padding-bottom: spacing(1);
    font-family: $font-family-strong;
  }

  .uuid {
    padding-bottom: spacing(1);
    font-size: 0.6rem;
    color: $medium-grey;
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .general,
  .tbo {
    width: 50%;
    display: grid;
    grid-template-columns: 6rem auto;
    row-gap: 0.0625rem;
    font-size: $xs-font-size;

    .label {
      font-family: $font-family-strong;
    }

    .value {
      padding-left: spacing(2);
      color: var(--accent-color);
    }
  }

  .NotEstablished {
    color: $cerulean;
  }
  .Broken {
    color: $dark-red;
  }
  .Established {
    color: $moderate-lime-green;
  }
}

.newNomination {
  font-size: 0.875rem;
  padding: spacing(1.5) spacing(2);
  margin-right: spacing(1.5);
  color: var(--primary-color);
  background-color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh {
  font-size: 0.875rem;
  padding: spacing(2) spacing(2);
  margin-right: spacing(1.5);
  color: $medium-grey;
  transition: color $default-easing;

  &:hover {
    color: $dark-grey;
  }

  svg {
    font-size: 1.5rem;
  }
}

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: spacing(2);

  h3 {
    width: fit-content;
    margin: spacing(2);
  }
}
