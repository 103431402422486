@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

$wrapping-horizontal-spacing: spacing(5);
$header-height: spacing(8);
$tab-height: spacing(6);

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 $wrapping-horizontal-spacing spacing(4);
  font-size: $small-font-size;
  font-family: $font-family-strong;
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;
  font-size: $xs-font-size;
}

.tableTotals {
  display: flex;
  font-size: $xs-font-size;
  align-items: center;

  .element {
    padding-right: $spacing;

    & + .element {
      padding-left: $spacing;
      border-left: 1px var(--text-color) solid;
    }
  }
}

.tableActionButton {
  transition: color $default-easing;
  color: $dark-grey;

  &:link,
  &:visited {
    color: $dark-grey;
  }

  &:focus,
  &:hover {
    color: $very-dark-blue;
  }
}
