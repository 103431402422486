@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.isDisabled {
  @include clickable;
  cursor: default;

  svg {
    fill: $disabled-grey;
    cursor: default;
  }

  input {
    @include input;
    color: $disabled-grey;
    pointer-events: none;
    cursor: default;

    &::placeholder {
      color: $disabled-grey;
      opacity: 1;
    }
  }
}

.clear {
  @include clickable;
  margin: auto 0.5rem;
  color: var(--accent-color);

  &:hover {
    color: $very-dark-blue;
  }

  &.isDisabled {
    color: $disabled-grey;
  }
}

.icon {
  @include round(spacing(4));
  color: $cerulean;

  &.activeIcon {
    color: $cerulean;
  }

  &.isDisabled {
    fill: $disabled-grey;
  }
}

.timeInput,
.dateInput {
  @include input;
  flex-grow: 1;
  background-color: transparent;
  color: $very-dark-blue;
}

.dateText {
  @include visuallyHidden;
}

.formRow {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-bottom: spacing(2);
  margin-top: spacing(2);
  width: 100%;
}

.label {
  font-size: $small-font-size;
  font-weight: 600;
  color: $very-dark-blue;
  margin-bottom: spacing(0.5);
}

.dateInputWrapper,
.timeInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(2);
  width: 100%;
}

.inputContainer,
.timeInputContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: $white;
  padding: 0 $xs-font-size;
  height: $xxl-font-size;
  margin-top: spacing(1);
  border: 1px solid $light-grey;
  border-radius: 4px;
  width: 100%;
  position: relative;
  max-width: spacing(50);
  width: spacing(50);
  gap: spacing(1);
}

.inputContainer .closeIcon {
  width: spacing(3);
  height: spacing(3);
}
