@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spacing.scss';

$checkbox-size: 18px;
$checkbox-border-width: 2px;

.checkboxButton {
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: $checkbox-size;
  height: $checkbox-size;
  background-color: $white;
  border: solid $checkbox-border-width $medium-grey;
  user-select: none;

  svg {
    font-size: 1rem;
  }

  &Disabled {
    cursor: not-allowed;
    background-color: $disabled-grey;
    border-color: $medium-grey;
  }
}

.checkboxChecked {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--accent-color);
  border-color: transparent;
  color: $white;

  &Disabled {
    background-color: $medium-grey;
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: spacing(2);
  margin: 0;

  height: $checkbox-size;
  line-height: $checkbox-size;
  color: var(--text-color);
  white-space: nowrap;
  @include clickable;
  @include overflowEllipsis();

  input {
    @include visuallyHidden;

    &:focus-visible {
      + span {
        border: solid $checkbox-border-width var(--accent-color);
      }

      &:checked + span {
        color: var(--accent-color);
        background: $white;
      }
    }
  }

  &Disabled {
    color: var(--disabled-color);
    cursor: default;
  }
}
