.drillDownLevel {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &Left {
    left: 0;

    &:not(.active) {
      transform: translate(-100%, 0);
    }
  }

  &Right {
    right: 0;

    &:not(.active) {
      transform: translate(100%, 0);
    }
  }

  &Left,
  &Right {
    transition: transform 0.3s;
  }
}
