@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

$table-row-font-size: $xs-font-size;
$table-row-line-height: 1.5;
$table-row-padding: 0.5em;

:export {
  rowHeight: calc($table-row-font-size * $table-row-line-height);
}

.table {
  width: 100%;
  font-size: $xs-font-size;
  background-color: var(--primary-color);
}

.tableHeader,
.tableHeaderButton {
  color: var(--accent-color);
  text-align-last: left;
  background-color: var(--primary-color);
  .cell {
    color: var(--accent-color);
    font-family: $font-family-strong;
  }

  & button {
    padding: 0;
    font-family: $font-family-strong;
  }
}

.odd {
  background: var(--selected-background-color);
}

.cell {
  text-align: left;
  font-family: $font-family-medium;
  font-size: $table-row-font-size;
  line-height: $table-row-line-height;
  padding: $table-row-padding 0;
  padding-left: spacing(2);
  white-space: nowrap;
  min-width: fit-content;
  color: #222;

  &:first-child {
    padding-left: spacing(3);
  }

  &:last-child {
    padding-right: spacing(3);
  }

  &.noResult {
    text-align: center;
    white-space: normal;
  }
}
