@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/a11y';
@import '../../styles/mixins';

$toggle-easing: $default-easing;
$toggle-icon-size: spacing(3);
$toggle-height: $toggle-icon-size + $spacing;
$toggle-padding: math.div($toggle-height - $toggle-icon-size, 2);
$toggle-width: ($toggle-icon-size * 2) + ($toggle-padding * 2);

.toggle {
  @include clickable();
  transition: background-color $toggle-easing;
  display: inline-block;
  border-radius: spacing(2);
  padding: $toggle-padding;
  width: $toggle-width;
  height: $toggle-height;
  text-align: left;
  background-color: $disabled-grey;

  &.enabled {
    background-color: var(--accent-color);
  }

  .disabled{
    opacity: 0.5;
    pointer-events: none;
  }
}

.toggleIcon {
  transition: transform $toggle-easing;
  transform: translateX(0);
  display: inline-block;
  border-radius: 50%;
  width: $toggle-icon-size;
  height: $toggle-icon-size;
  box-shadow: 0 2px 4px $shadow-color;
  background-color: $white;

  .enabled & {
    transform: translateX(100%);
  }
}


