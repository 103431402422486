@import '../../styles/variables';
@import '../../styles/spacing';

.timeInput {
  padding: spacing(2);
  border: none;
}

.label {
  padding: 0 spacing(2);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    fill: $cerulean;
  }

  &.isDisabled {
    > svg {
      fill: $disabled-grey;
    }

    input {
      color: $disabled-grey;
      &::placeholder {
        color: $disabled-grey;
        opacity: 1;
      }
    }
  }

  .timeInput {
    flex-grow: 1;
  }
}
