@import '../../styles/variables';
@import '../../styles/spacing';

.docContainer {
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: spacing(6);
}

nav {
  position: relative;
  > ul {
    margin: 0;
    padding: 0;
    min-width: 12rem;
    background-color: var(--primary-color);
    position: fixed;
    list-style: none;
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: var(--text-color);
        text-decoration: none;
        padding: spacing(3);

        &:hover {
          background-color: var(--selected-background-color);
        }
      }
      & + li {
        border-top: 1px var(--border-color) solid;
      }
    }
  }
}

.doc {
  margin: 0 spacing(4) spacing(8) spacing(4);
  width: 52rem;
  min-height: calc(100% - spacing(8));
  max-width: 52rem;
  padding: spacing(6) spacing(16) spacing(16) spacing(10);
  background-color: var(--primary-color);

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h2 {
    color: $magenta;
    border-bottom: 1px solid $medium-grey;
    margin-block-start: spacing(5);
    margin-block-end: spacing(3);
  }

  h3 {
    margin-block-start: spacing(3);
    margin-block-end: spacing(1.5);
  }

  h4 {
    color: $cerulean;
    margin-block-start: spacing(2);
    margin-block-end: spacing(1);
  }

  ul {
    padding-inline-start: spacing(5);
    margin-block-start: spacing(1);
    margin-block-end: spacing(2);
  }

  ol {
    padding-inline-start: spacing(5);
    margin-block-start: spacing(2);
    margin-block-end: spacing(2);
  }

  li {
    margin-block-start: spacing(1);
    margin-block-end: spacing(1);
  }

  pre {
    font-family: Menlo, 'monospace';
    display: inline;
  }

  .docLinks {
    display: flex;
    flex-direction: column;

    a {
      &:link,
      &:visited,
      &:hover {
        color: inherit;
        text-decoration: none;
        display: flex;
        color: var(--accent-color);
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        padding: $spacing 0;
      }

      &:hover {
        color: var(--text-color);
        background-color: var(--selected-background-color);
      }
    }
  }
}
