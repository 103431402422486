@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/mixins.scss';
.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 3px;
    font-family: $font-family-strong;
    background-color: var(--accent-color);
    color: var(--primary-color);
    padding: $spacing spacing(3);
  }
}

.content {
  padding: spacing(2) spacing(3) 0;

  section + form,
  form + section,
  section + section {
    padding-top: spacing(4);
  }

  .results {
    display: flex;
    flex-direction: column;
    padding-bottom: spacing(12);
    gap: spacing(3);

    .check {
      fill: $moderate-lime-green;
    }
    .legend {
      display: flex;
      gap: spacing(3);
      justify-content: flex-start;
      .entry {
        display: flex;
        align-items: center;
        gap: $spacing;
        &::before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
        }
        &:first-child::before {
          background-color: rgba(#007e91, 0.1);
        }
        &:nth-child(2)::before {
          background-color: rgba(#007e91, 0.2);
        }
        &:last-child::before {
          background-color: rgba(#007e91, 0.3);
        }
      }
    }
    .nominations {
      display: flex;
      gap: spacing(3);

      .nominationTable {
        flex-shrink: 0;
        height: auto;
        width: 54rem;
      }

      .nominationList {
        padding: spacing(2) 0;
        display: flex;
        flex-direction: column;
        gap: $spacing;
        &Title {
          font-family: $font-family-strong;
          font-size: $small-font-size;
          padding-bottom: $spacing;
          .to {
            font-family: $font-family-regular;
          }
        }
      }
    }
  }
}

.route {
  display: flex;
  font-size: $small-font-size;
  gap: spacing(2);

  label {
    align-items: center;
    display: flex;
    gap: $spacing;

    input {
      font-size: $small-font-size;
      border: 1px $light-grey solid;
      padding: $spacing;
      background-color: var(--primary-color);
    }
  }
}

.tableSection {
  height: 24rem;

  .tableContainer {
    overflow: auto;
    height: 100%;
    background-color: var(--primary-color);
  }

  .table {
    thead {
      position: sticky;
      top: 0;
      background-color: var(--primary-color);
      z-index: $table-header-index;
    }

    .groupHeader {
      background: yellow;
      border-top: 2px black solid;
      font-family: $font-family-strong;

      .element {
        position: relative;
        float: left;

        & + .element {
          padding-left: spacing(2);
          border-left: 1px var(--primary-color) solid;
          margin-left: spacing(2);
        }
      }
      &.nominatedGroupHeader {
        background-color: var(--accent-color);
      }
    }
  }

  .tableActions {
    display: flex;
    gap: spacing(2);
    align-items: center;

    &.nominations {
      align-self: flex-end;
    }
  }
}

.formSection {
  font-size: $small-font-size;
  display: flex;
  gap: spacing(3);
  align-items: center;
  justify-content: flex-start;

  .entry {
    min-width: 20rem;

    .label {
      display: flex;
      font-family: $font-family-strong;
      gap: spacing(2);
      align-items: center;

      .input {
        border: 1px $light-grey solid;
        padding: $spacing;
        background-color: var(--primary-color);
        font-family: $font-family-strong;
        color: var(--text-color);
        width: 4rem;
      }

      .minMax {
        font-family: $font-family-regular;
      }
    }
  }
  button[type='submit'] {
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-strong;
    font-size: $small-font-size;
    background-color: var(--accent-color);
    color: var(--primary-color);
    padding: $spacing spacing(3);

    .spinner::before {
      content: '';
      margin-right: $spacing;
      @include spinner(0.75rem, $white, rgba($white, 0.3), 0.125rem);
    }
  }
}
