@import '../../../styles/spacing';
@import '../../../ui/DatePicker/DatePicker.module.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';



.popover {
  position: relative;
  align-self: stretch;
}

.panel {
  position: fixed;
  z-index: $modal-index;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 1px;
  display: flex;
  align-items: stretch;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--primary-color);
  padding-top: spacing(6);

  .title {
    padding: 0 spacing(6);
    margin: 0;
  }

  footer {
    button {
      font-family: $font-family-strong;
      padding: spacing(2) spacing(3);
      margin-left: spacing(5);
      grid-column: 5/5;
      color: var(--primary-color);
      background: var(--accent-color);
      border-radius: 3px;
    }
  }
}

.columns {
  $width: 16.5rem;
  display: grid;
  grid-template-columns: $width spacing(10) $width spacing(10) $width;
  grid-auto-flow: column;
  padding: spacing(4) spacing(6);
  flex-grow: 1;

  hr {
    border: none;
    border-left: var(--border-color) 1px solid;
  }
}

.column {
  display: flex;
  flex-direction: column;
  font-size: $small-font-size;

  .label {
    padding-top: spacing(4);
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  &Title {
    padding-bottom: spacing(3);
    font-family: $font-family-strong;
  }

  &Entry {
    position: relative;
    display: flex;
    flex-direction: column;

    > :first-child {
      padding-bottom: $spacing;
    }

    .select {
      border: var(--border-color) 1px solid;

      label {
        padding: $spacing 0;
      }
    }

    .input {
      border: var(--border-color) 1px solid;
      display: flex;
      align-items: center;
      padding: 0 spacing(2);

      svg {
        fill: $cerulean;
      }

      &.isDisabled {
        input {
          color: $disabled-grey;

          &::placeholder {
            color: $disabled-grey;
            opacity: 1;
          }
        }

        svg {
          fill: $disabled-grey;
        }
      }
    }

    .clearButton {
      align-self: flex-end;
      background: $disabled-grey;
      padding: $spacing spacing(3);

      &:not(:disabled):hover {
        color: var(--primary-color);
        background: var(--accent-color);
      }
    }

    .timeInput {
      padding: spacing(2);
      border: none;
      flex-grow: 1;
    }

    .draught {
      display: flex;
      gap: spacing(4);

      &Entry {
        display: flex;
        max-width: 40%;
        align-items: center;
        justify-content: space-between;

        :first-child {
          width: 2rem;
        }

        .nr {
          max-width: 3.5rem;
          flex-grow: 1;
          margin-left: $spacing;
          padding: $spacing spacing(2);
          border: var(--border-color) 1px solid;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $disabled-grey;
            opacity: 1;
            /* Firefox */
          }
        }

        .readOnly {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .range {
      &Menu {
        width: 100%;

        &Item {
          padding: spacing(2);
        }
      }

      &Button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: var(--border-color) 1px solid;
        padding: spacing(2);

        .selected {
          display: flex;
          align-items: center;
        }

        svg {
          fill: $cerulean;
          margin-right: spacing(2);
        }
      }
    }

    &+.columnEntry {
      padding-top: spacing(4);
      margin-top: spacing(3);
      margin-bottom: spacing(3);
    }
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
}

.disabledEntry,
.disabledLabel {
  opacity: 0.5;
  pointer-events: none;
}

.disabledInput,
.readOnly {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.includeTboContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: spacing(2);
}

.tboInput {
  flex: 1;
  padding: spacing(2);
  border: 1px solid $medium-light-grey;
  border-radius: 4px;
  font-family: $font-family-regular;
}

.addButton {
  padding: spacing(1) spacing(2);
  background-color: $cerulean;
  color: $white;
  border: none;
  cursor: pointer;
  margin-left: -5px;
  border-radius: 0 4px 4px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton:disabled {
  background-color: $disabled-grey;
  cursor: not-allowed;
}

.tboList {
  margin-top: spacing(2);
}

.noTbo {
  @include clickable;
  pointer-events: none;
  color: $disabled-grey;
}

.tboItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: spacing(1);
  background-color: $light-grey;
  border-radius: 4px;
  margin-top: spacing(1);
}

.removeButton {
  @include clickable;
  color: $warning-color;
  border: none;
  border-radius: 4px;
  padding: spacing(1);
  display: flex;
}
