@import '../../../styles/variables.scss';
@import '../../../styles/spacing.scss';

.ad {
  padding: spacing(3);
  margin: 1rem 0;

  h3 {
    margin: spacing(2) 0;
    &:first-child {
      margin-top: 0;
    }
    svg {
      height: 1.125rem;
      margin-bottom: 3px;
      margin-right: 3px;
    }
  }

  p {
    margin: spacing(2) 0;
  }

  &.note {
    background-color: $light-blue-grey;
  }

  &.warning {
    background-color: $light-yellow-grey;
  }
}
