@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.popover {
  position: relative;

  svg {
    fill: var(--text-color);
  }

  .panel {
    font-family: $font-family-regular;
    padding: spacing(4);
  }
}
