@import './NLRTM-font-family';

$white: #fff;
$black: #000;
$warning-color: #db392a;
$shadow-color: rgba($black, 0.3);
$dark-grey: #757575;
$medium-grey: #a8a8a8;
$medium-light-grey: #e6e4e2;
$disabled-grey: #ddd;
$light-grey: #f2f1f1;
$light-yellow-grey: #fff4e0;

// Zeplin
$light-blue-grey: #e0eef4;
$cerulean: #0693ca;
$light-blue: #73bee6;
$magenta: #e6007e;
$grape: #643c73;
$ocean: #007e91;

// https://www.colorhexa.com/
$very-dark-blue: #1c2a55;
$bright-red: #db392a;
$dark-red: #ab192d;
$very-light-blue: #f1f7fa;
$moderate-lime-green: #46be41;
$yellow: #ffd800;
$grayish-orange: #ccc8c5;
$strong-blue: #077ab7;
$orange: #f39900;

//==================
// Typography
//==================
$default-font-size: 1rem;
$xxs-font-size: 0.6875rem; // 11
$xs-font-size: 0.75rem; // 12
$small-font-size: 0.875rem; // 14
$large-font-size: 1.25rem; // 20
$xl-font-size: 1.625rem; // 26
$xxl-font-size: 3rem; // 48

$font-family-strong: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
$font-family-medium: 'NLRTM-Medium', 'Trebuchet MS', Arial, sans-serif;
$font-family-regular: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;

//==================
// Sizes
//==================
$header-height: 50px;

//==================
// Z-Indexes
//==================
$table-header-index: 1;
$modal-index: 200;
$new-tow-drawer-index: 101;
$account-menu-index: 100;
$account-menu-overlay-index: 99;
$primary-menu-index: 49;
$dropdown-menu-index: 48;
$table-config-panel-zindex: 47;
//==================
// Animations and transitions
//==================
$default-easing: 0.1s ease-in-out;
