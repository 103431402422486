@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/spacing';

$spinnerSize: 1em;
$modalPadding: 2.5rem;

.modalParent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $modal-index;
  padding: 0.75rem;
  box-sizing: border-box;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.6);
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 32rem;
  max-height: calc(100vh - 4rem);
  background-color: $white;
  z-index: $modal-index + 1;
}

.content {
  color: $very-dark-blue;
  padding: $modalPadding;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  flex-grow: 1;
}

.title {
  font-family: $font-family-strong;
  font-size: $large-font-size;
  flex-shrink: 0;
  margin: 0;
}

.text {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: $font-family-regular;
  font-size: $small-font-size;
  white-space: pre-wrap;
  overflow: hidden;
  flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px $light-grey solid;
  padding: spacing(3);
  gap: spacing(2);
}

.button {
  font-family: $font-family-strong;
  font-size: $small-font-size;
  padding: 0.5em 1em;
  border-radius: 3px;

  color: $white;
  background-color: $very-dark-blue;
  display: flex;
  gap: $spacing;

  &.primary {
    background-color: $cerulean;

    &.isLoading::before {
      content: '';
      @include spinner(0.875rem, $white, rgba($white, 0.3), 0.125rem);
    }
  }

  &:disabled {
    color: $medium-grey;
    background-color: $disabled-grey;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: spacing(2);
  color: $white;
  background-color: $cerulean;
  > svg {
    font-size: 1.5rem;
  }
}

.updateBargesModal {
  max-width: 60rem;
  overflow: hidden;
  height: 100vh;

  .result {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.hasResult {
      border: 1px $light-grey solid;
      overflow-y: auto;
    }

    &.hasFailure {
      border: none;
      align-items: flex-start;
    }
  }

  .failure {
    padding: spacing(3) spacing(4);
    color: var(--primary-color);
    background-color: $warning-color;
    border-radius: 3px;

    .message {
      font-size: $default-font-size;
      margin: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: spacing(5);
    padding-bottom: spacing(2);
  }

  .export {
    @clickable;
    color: var(--accent-color);
    border: 1px var(--accent-color) solid;
    font-size: $xs-font-size;
    padding: $spacing spacing(2);
    border-radius: 3px;

    &:disabled {
      color: $disabled-grey;
      border-color: $disabled-grey;
      pointer-events: none;
    }
  }
}
