@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.banner {
  margin: spacing(2) spacing(6);
  padding: spacing(2);
  border: 1px solid $orange;
  background: rgba($orange, 0.25);
  border-radius: 3px;
  text-align: center;
}

.topBannerBarge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: spacing(2);
}

.container {
  height: calc(100vh - 50px);
  display: flex;
  padding: spacing(4) spacing(6);
  overflow: hidden;
}

.section {
  background-color: var(--primary-color);
}

.formSection {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  align-content: space-between;
  height: 100%;
  overflow: hidden;
  max-width: calc(20% - spacing(5));
  margin-right: spacing(5);

  .formDataBlocks {
    display: flex;
    flex-grow: 1;
    align-content: flex-start;
    flex-direction: column;
    max-height: calc(100% - 60px);
    overflow: hidden;
  }

  .formSubmitBlock {
    padding: spacing(2);
    height: 60px;
    align-content: flex-end;

    button {
      @include clickable;
      font-size: $small-font-size;
      padding: $xxs-font-size $default-font-size;
      color: $white;
      border: none;
      border-radius: spacing(1);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $cerulean;
      width: 100%;
    }

    .formWithActions {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: spacing(2);
    }

    .actionBlock {
      display: flex;
      justify-content: space-between;
      margin-top: spacing(4);
      gap: $default-font-size;

      .prev,
      .next {
        background-color: $cerulean;
        width: 5rem;
      }

      .next {
        margin-left: auto;
      }
    }

    .formBlock {
      flex-grow: 1;
    }

    .summaryBlock {
      @include clickable;
      padding: spacing(2);
      border: 1px solid $disabled-grey;
      background: $light-yellow-grey;
      transition: all 0.33s ease-out;
      box-sizing: border-box;
      height: 25%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        background: $light-blue-grey;
      }
    }

    .summaryContainer {
      font-size: $small-font-size;
      color: $dark-grey;
    }

    .blockTitle {
      font-family: $font-family-strong;
      margin-bottom: spacing(1);
    }
  }

  button:disabled {
    color: $medium-grey;
    background-color: $medium-light-grey;
  }

  .bargePoolSection {
    max-width: 70%;
    flex: 1;
    justify-content: center;
    align-items: center;

    .bargePoolTitle {
      font-family: $font-family-strong;
      margin: spacing(3) spacing(1);
    }

    .bargePoolWrapper {
      height: 95%;
      overflow-y: scroll;
    }

    .bargePoolRoot {
      height: 100vh;
    }

    .centeredSpinner {
      position: relative;
      left: 0;
      top: 50%;
    }

    button:disabled {
      color: $medium-grey;
      background-color: $medium-light-grey;
    }
  }

  .nominationForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .formWithActions {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: spacing(2);
  }

  .actionBlock {
    display: flex;
    justify-content: space-between;
    margin-top: $default-font-size;
    gap: $default-font-size;

    .prev,
    .next {
      width: 5rem;
    }

    .next {
      margin-left: auto;
    }
  }

  .formBlock {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .summaryBlock {
    @include clickable;
    padding: spacing(2);
    border: 1px solid $disabled-grey;
    background: $light-yellow-grey;
    transition: all 0.33s ease-out;
    box-sizing: border-box;
    height: 10%;
    min-height: 10%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &:hover {
      background: $light-blue-grey;
    }
  }

  .summaryContainer {
    font-size: $small-font-size;
    color: $dark-grey;
  }

  .blockTitle {
    font-family: $font-family-strong;
    margin-bottom: spacing(1);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .iconValid {
      width: spacing(4);
      height: spacing(4);
      margin-left: spacing(1);
      color: $moderate-lime-green;
    }

    .iconInvalid {
      width: spacing(4);
      height: spacing(4);
      margin-left: spacing(1);
      color: $dark-red;
    }
  }
}

.bargePoolSection {
  max-width: 80%;
  flex: 1 1 80%;
  justify-content: center;
  align-items: center;

  .bargePoolRoot {
    height: 100%;
  }

  .bargePoolWrapper {
    border-top: 1px solid $medium-light-grey;
    height: 95%;
  }

  .centeredSpinner {
    position: relative;
    left: 0;
    top: 50%;
  }

  .tabNavigation {
    display: flex;
    padding: spacing(2) spacing(4) 0;
    margin-bottom: -1px;
  }

  .tabButton {
    padding: spacing(2) spacing(4);
    margin-right: 1px;
    border: 1px solid $medium-light-grey;
    border-radius: 3px 3px 0 0;
    background: $medium-light-grey;
    color: $dark-grey;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .tabButton:not(.activeTab):hover {
    background: $light-blue-grey;
  }

  .activeTab {
    background: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    font-weight: 600;
    color: $cerulean;
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    min-height: 400px;
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacing(4);
}

.boatName,
.boatNameText {
  font-size: 14px;
  color: $light-grey;
  margin: 0;
}

.formRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label {
  font-size: $small-font-size;
  font-weight: 600;
  margin-bottom: $xxs-font-size;
  margin-top: spacing(2);
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid $disabled-grey;
  padding: 0;
  height: $xxl-font-size;
  width: 100%;
  position: relative;
}

.comboboxInput {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 $xxs-font-size;
}

.comboboxInput label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $xxs-font-size;
  width: 100%;
  height: 100%;
  margin-right: auto;
}

.comboboxInput .MuiSvgIcon-root {
  margin-right: $xxs-font-size;
  height: $default-font-size;
}

.comboboxInput input {
  border: none;
  outline: none;
  width: 100%;
  font-size: $small-font-size;
  line-height: spacing(1.5);
}

.comboboxButton {
  @include clickable;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 100%;
  padding: 0 $xxs-font-size;
  position: relative;
}

.menuItem {
  padding: $xxs-font-size;
  border-bottom: 1px solid $disabled-grey;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: $default-font-size;
}

.button {
  @include clickable;
  font-size: $small-font-size;
  padding: $xxs-font-size $default-font-size;
  color: $white;
  border: none;
  border-radius: spacing(1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.boatDetailsContainer,
.turnboatContainer {
  display: flex;
  align-items: center;
  gap: spacing(4);
  padding: spacing(2);
  margin-top: spacing(2);
}

.turnboatLabel,
.boatNameText {
  font-weight: bold;
  font-size: $small-font-size;
  color: $dark-grey;
  margin: 0;
  line-height: 1;
}

.disabledToggle,
.enabledToggle {
  @include clickable;
  opacity: 0.5;
}

.enabledToggle {
  @include clickable;
  opacity: 1;
}

.flexTurnboat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: spacing(6);
  margin-bottom: spacing(1);
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-top: spacing(6);
}

.iconContainer {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.icon {
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  color: $light-blue-grey;
}

.icon:hover {
  transform: scale(1.2);
}

.active {
  color: $moderate-lime-green;
}

.inactive {
  color: $warning-color;
}

.closeButton {
  @include clickable;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 50px;
  appearance: none;
  outline: none;
  border-left: solid 1px $medium-light-grey;
  border-right: solid 1px $medium-light-grey;

  .icon {
    color: var(--text-color);
    font-size: 22px;
  }

  &.toggleItem {
    padding: 0 spacing(4);

    .label {
      padding-left: $spacing;
      font-family: $font-family-strong;
      font-size: $xs-font-size;
      color: var(--text-color);
    }
  }
}

.buttonExport {
  width: 12rem;
  font-size: $small-font-size;
  padding: spacing(1) spacing(2);
  color: $strong-blue;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid $strong-blue;
  margin-top: spacing(2);
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: $light-blue-grey;
    color: $strong-blue;
    border-color: $strong-blue;
  }

  &:disabled {
    @include clickable;
    background-color: $disabled-grey;
    color: $light-grey;
    border: 1px solid $light-grey;
  }
}
