@import './variables.scss';
@import './spacing.scss';

:global {
  body {
    .MuiSvgIcon-root {
      display: inline-block;
      vertical-align: middle;
      font-size: $large-font-size;
    }

    .MuiTooltip-tooltip {
      font-family: $font-family-regular;
      color: $white;
      background: $very-dark-blue;
    }

    .MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip {
      margin-bottom: $spacing;
    }

    .MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip {
      margin-top: $spacing;
    }

    .MuiTooltip-popper[data-popper-placement*='left'] .MuiTooltip-tooltip {
      margin-right: $spacing;
    }

    .MuiTooltip-popper[data-popper-placement*='right'] .MuiTooltip-tooltip {
      margin-left: $spacing;
    }
  }
}
