@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.container {
  display: grid;
  padding: spacing(4) spacing(6);
  column-gap: spacing(5);
}

.section {
  &.columns {
    padding: spacing(4);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: var(--primary-color);
  }

  .column:not(:last-child) {
    margin-right: spacing(2);
    border-right: 1px solid $light-grey;
  }

  &.nativeNomination {
    background-color: $light-blue;
    padding: spacing(2) spacing(4);

    .labelValuePair {
      font-size: $xs-font-size;
      font-family: $font-family-strong;
      color: $very-dark-blue;

      .value {
        color: $very-dark-blue;
      }
    }
  }

  &.externalNomination {
    background-color: $orange;
    padding: spacing(2) spacing(4);

    .labelValuePair {
      font-size: $xs-font-size;
      font-family: $font-family-strong;
      color: $very-dark-blue;

      .value {
        color: $very-dark-blue;
      }
    }
  }

  &.journey {
    background-color: var(--primary-color);
    padding: spacing(4);

    .labelValuePair {
      padding: 0 spacing(12);
      grid-template-columns: 13rem 1rem auto;

      .label {
        grid-column: 1;
        font-family: $font-family-strong;

        &.padded {
          padding-left: spacing(2);
        }
      }

      .value {
        grid-column: 3;
        padding-left: spacing(2);
        color: var(--accent-color);
      }
    }
  }

  &.nominationSummary {
    background-color: var(--primary-color);
    padding: spacing(4);
  }

  .tbnStatistics {
    margin-bottom: spacing(1);
  }

  .hotStatistics,
  .tboStatistics {
    margin-top: spacing(2);
  }
}

.nominationSummary {
  display: flex;
  flex-direction: row;

  .info {
    flex-grow: 1;
  }

  .actions {
    padding-top: spacing(4);
  }
}

.actions,
.versionActions {
  .button {
    width: 12rem;
    font-size: $small-font-size;
    padding: spacing(1) spacing(2);
    color: var(--primary-color);
    background-color: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid var(--accent-color);
    margin-top: spacing(2);

    &:disabled {
      background-color: $disabled-grey;
      cursor: not-allowed;
    }

    &:hover {
      border-style: solid;
      border-color: var(--accent-color);
      background: $light-blue-grey;
      color: var(--accent-color);
    }
  }

  .button.danger {
    background-color: $dark-red;
    border-color: $dark-red;

    &:hover {
      border-style: solid;
      border-color: $dark-red;
      background: $light-blue-grey;
      color: $dark-red;
    }
  }
}

.versionNavigation {
  display: flex;
  flex-direction: row;
  padding: 0 spacing(6);

  .versionLink {
    border: 1px solid $medium-light-grey;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    padding: spacing(2);
    margin-right: 1px;
    display: block;
    width: 12rem;
    font-size: 0.8rem;
    text-align: center;
    text-decoration: none;
    color: $very-dark-blue;

    &.native {
      background-color: rgba($light-blue, 0.05);

      &.active {
        box-shadow: inset 0 -3px 0 0 $light-blue;
        background-color: rgba($light-blue, 0.15);
      }
    }

    &.external {
      background-color: rgba($orange, 0.15);

      &.active {
        box-shadow: inset 0 -3px 0 0 $orange;
      }
    }
  }
}

.versionActions {
  display: flex;
  padding: spacing(2) spacing(6) 0;
  align-items: baseline;
  justify-content: flex-end;
  gap: spacing(2);

  .refresh {
    font-size: 0.875rem;
    padding: spacing(2) 0;
    margin-right: spacing(1.5);
    color: $medium-grey;
    transition: color $default-easing;

    &:hover {
      color: $dark-grey;
    }

    svg {
      font-size: 1.5rem;
    }
  }
}

.version {
  border-top: 1px solid $medium-light-grey;

  &.native {
    background-color: rgba($light-blue, 0.15);
  }

  &.external {
    background-color: rgba($orange, 0.15);
  }
}

.labelValuePair {
  font-size: $xxs-font-size;
  padding-top: spacing(1);
  display: grid;
  grid-template-columns: 10rem 1rem auto;

  .label {
    grid-column: 1;
    font-family: $font-family-strong;
    width: 5fr;

    &.padded {
      padding-left: spacing(2);
    }
  }

  .value {
    grid-column: 3;
    color: var(--accent-color);
    width: spacing(27);
  }

  .orderLink {
    color: var(--accent-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: spacing(1);

    &:hover {
      color: $very-dark-blue;
      text-decoration: underline;
    }
  }

  .orderLinkIcon {
    font-size: 12px;
    vertical-align: middle;
  }
}

.nominatedBarges {
  background-color: var(--primary-color);

  .bargeListHeader {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e6e4e2;
    display: flex;
    gap: spacing(3);
    padding: spacing(3) spacing(4);
    justify-content: flex-end;
  }

  .pinCell {
    width: 4.5rem;
  }

  .nameCell {
    width: 4.5rem;
  }

  .cell {
    min-width: 6rem;
  }

  .isAtRiskCell {
    width: 4.5rem;
  }

  .tboCell {
    width: 3.5rem;
  }

  .inOtherNominationCell {
    width: 6rem;
  }

  .hullTypeCell,
  .isAvailableCell {
    width: 5.5rem;
  }

  .locationCell {
    min-width: 5.5rem;
  }
}

.towActionButton {
  transition: color $default-easing;
  color: $dark-grey;

  &:focus,
  &:hover {
    color: $very-dark-blue;
  }
}
