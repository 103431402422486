@import '../../../styles/variables';

.pending {
  color: $disabled-grey;
}

svg.status {
  color: $warning-color;
  font-size: 1rem;

  &.isOk {
    color: $moderate-lime-green;
  }

  &.isStale {
    color: $orange;
  }

  &.isVeryStale {
    color: $yellow;
  }
}
