@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.nomination {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--primary-color);
  min-width: 50.5rem;

  &.hasReviews {
    min-width: 62rem;
  }

  .towActionButton {
    transition: color $default-easing;
    color: $dark-grey;

    &:focus,
    &:hover {
      color: $very-dark-blue;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: spacing(3);
    padding: spacing(3) spacing(4);
    background-color: #fff;
    border-bottom: 1px solid $medium-light-grey;
  }

  .nameCell {
    width: 4.5rem;
  }

  .cell {
    min-width: 6rem;
  }

  .isAtRiskCell {
    width: 4.5rem;
  }

  .tboCell {
    width: 3.5rem;
  }

  .inOtherNominationCell {
    width: 6rem;
  }
  .hullTypeCell,
  .isAvailableCell {
    width: 5.5rem;
  }
  .locationCell {
    min-width: 5.5rem;
  }
}

.selectors {
    display: flex;
    gap: spacing(5); 
    margin-right: spacing(5);
}
