@import '../../styles/variables.scss';

.ribbon {
  width: 12.1em;
  height: 12.1em;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
  font-size: 13px;
  text-decoration: none;
  text-indent: -999999px;
  opacity: 0.1;

  &.fixed {
    position: fixed;
  }

  &:before,
  &:after {
    /* The right and left classes determine the side we attach our banner to */
    position: absolute;
    display: block;
    width: 15.38em;
    height: 1.54em;

    top: 3.23em;
    right: -3.23em;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:before {
    content: '';

    /* Add a bit of padding to give some substance outside the "stitching" */
    padding: 0.38em 0;

    /* Set the base colour */
    background-color: $cerulean;

    /* Set a gradient: transparent black at the top to almost-transparent black at the bottom */
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.15)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));

    // /* Add a drop shadow */
    -webkit-box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);

    pointer-events: auto;
  }

  &:after {
    /* Set the text from the data-ribbon attribute */
    content: attr(data-ribbon);

    font-family: $font-family-strong;
    /* Set the text properties */
    color: $very-light-blue;
    //font: 700 1em 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.54em;
    text-decoration: none;
    // text-shadow: 0 -0.08em rgba(0, 0, 0, 0.5);
    text-align: center;
    text-indent: 0;

    /* Set the layout properties */
    padding: 0.15em 0;
    margin: 0.15em 0;

    white-space: nowrap;
  }

  &.left-top,
  &.left-bottom {
    right: auto;
    left: 0;
  }

  &.left-bottom,
  &.right-bottom {
    top: auto;
    bottom: 0;
  }

  &.left-top:before,
  &.left-top:after,
  &.left-bottom:before,
  &.left-bottom:after {
    right: auto;
    left: -3.23em;
  }

  &.left-bottom:before,
  &.left-bottom:after,
  &.right-bottom:before,
  &.right-bottom:after {
    top: auto;
    bottom: 3.23em;
  }

  &.left-top:before,
  &.left-top:after,
  &.right-bottom:before,
  &.right-bottom:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
