@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.toast {
  box-shadow: 0 0.875rem 1.5rem 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  font-size: 0.895rem;
  padding: spacing(2);
  min-width: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: spacing(2);

  svg {
    font-size: 1.2rem;
  }

  .errorIcon {
    fill: $warning-color;
  }

  .successIcon {
    fill: $moderate-lime-green;
  }

  &Content {
    display: flex;
    align-items: center;
    gap: spacing(1);
    justify-content: center;
  }
  .toastType {
    font-family: $font-family-strong;
    margin-right: spacing(1);
  }
} 
