@import '../../styles/variables.scss';

.cross {
  color: rgba(0,0,0,0.12);
  &.excluded {
    color: rgb(191, 75, 64);
    &:hover {
      color: rgb(191, 75, 64);
    }
  }
  &:hover {
    color: rgba(191, 75, 64, 0.35);
  }
}
