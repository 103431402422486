@import '../../styles/variables';
@import '../../styles/spacing';
@import '../../styles/mixins';

.button {
  @include clickable;
  font-size: $small-font-size;
  padding: spacing(2) spacing(4);
  color: $white;
  border: 1px solid $cerulean;
  background-color: $cerulean;
  border-radius: spacing(1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: $disabled-grey;
    cursor: not-allowed;
  }

  &:focus,
  &:hover {
    border: 1px solid var(--accent-color);
    background: $light-blue-grey;
    color: var(--accent-color);
  }
}
