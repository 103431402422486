@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

.nominationItemHeader {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

.nominationItemTbnBarges {
  display: flex;
  gap: $spacing;
  font-size: $xs-font-size;
  font-family: $font-family-regular;

  .tbnBarges {
    font-family: $font-family-strong;

    .number {
      border-radius: 3px;
      color: $white;
      background-color: $moderate-lime-green;
      padding: $spacing spacing(1.5);

      &.hasTbnBarges {
        background-color: $warning-color;
      }
    }
  }
}

.nominationItemActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
  font-family: $font-family-regular;
  font-size: $xs-font-size;
}

.nominationItemRightActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
  font-family: $font-family-regular;
  font-size: $xs-font-size;
}

.towActionButton {
  transition: color $default-easing;
  color: $dark-grey;

  &:focus,
  &:hover {
    color: $very-dark-blue;
  }
}

.metricWrapper {
  display: flex;
  align-items: center;

  .popover {
    align-self: start;
    button {
      display: flex;
      padding: 0;

      svg {
        font-size: 0.875rem;
      }
    }

    h3 {
      white-space: nowrap;
      font-size: $small-font-size;
    }
  }
}

.metric {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: spacing(2);
  padding: 1px $spacing;
  font-family: $font-family-strong;
  font-size: $xxs-font-size;
  line-height: 1;
  color: $very-dark-blue;
  background: $yellow;
}
