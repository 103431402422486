@use 'sass:math';
@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';

.journey {
  $size: 20px;
  padding: 0 spacing(12) spacing(2);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  gap: spacing(2);
  height: 7.5rem;

  .towSpecs {
    display: flex;
    font-size: $small-font-size;
    text-align: center;
    padding-bottom: $spacing;

    strong {
      font-family: $font-family-strong;
      padding-left: $spacing;
    }
  }

  .infoJourney{
    display: flex;
    justify-content: space-between;
  }

  .bar {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .stops {
      position: absolute;
      width: 100%;
      height: $size;
      &:after {
        content: '';
        display: inline-block;
        left: $size + 2px;
        right: $size + 2px;
        height: 2px;
        position: absolute;
        top: math.div($size, 2);
        background: repeating-linear-gradient(to right, transparent, transparent 2px, $cerulean 2px, $cerulean 7px);
      }
    }
    .transitTime {
      margin-top: 1px;
      height: $size;
      position: relative;
      font-size: $xs-font-size;
      display: flex;
      font-family: $font-family-strong;
      background-color: var(--primary-color);
      align-items: center;
      svg {
        color: var(--accent-color);
      }
    }
    .node {
      position: relative;
      display: block;
      padding-top: 1px;

      .button {
        position: relative;
        width: $size;
        height: $size;
        border-radius: math.div($size, 2);
        border: $cerulean 1px solid;
        background-color: $white;
        overflow: hidden;
        @include clickable;

        &.isActive:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          display: block;
          $size2: $size - 6px;
          width: $size2;
          height: $size2;
          border-radius: math.div($size2, 2);
          background-color: $cerulean;
          transform: translate(-50%, -50%);
        }
      }

      &Label {
        position: absolute;
        white-space: nowrap;
        font-family: $font-family-strong;
        font-size: $xs-font-size;
        transform-origin: bottom left;
        transform: rotate(-45deg) translate(25px, -5px);
      }
      &Minus,
      &Plus {
        display: flex;
        font-size: $xs-font-size;
        white-space: nowrap;
        align-items: center;
        position: absolute;
      }
      &Plus {
        left: 100%;
        bottom: spacing(3);
        > svg {
          fill: $moderate-lime-green;
        }
      }
      &Minus {
        right: 100%;
        bottom: spacing(3);
        > svg {
          fill: $warning-color;
        }
      }
    }
  }
}
