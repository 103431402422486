@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/spacing.scss';
@import '../../styles/triangle.scss';

.menu {
  position: relative;
  height: 100%;
}

.button {
  display: flex;
  &::after {
    content: '';
    margin-left: $spacing;
  }

  &[data-headlessui-state=''] {
    &::after {
      @include css-triangle('bottom', var(--accent-color), 0.35rem, 0.7rem);
    }
  }

  &[data-headlessui-state='open'] {
    &::after {
      content: '';
      margin-left: $spacing;
      @include css-triangle('top', var(--accent-color), 0.35rem, 0.7rem);
    }
  }
}

.optionsWrapper {
  position: relative;
  width: 100%;
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  border: 1px var(--border-color) solid;
  z-index: $dropdown-menu-index;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.option {
  @include clickable;
  @include overflowEllipsis;
  display: flex;
  align-items: baseline;
  font-size: $small-font-size;
  padding: spacing(3) spacing(7);
  line-height: 1;

  &[data-headlessui-state='active'] {
    background-color: $light-blue-grey;
  }
  &[data-headlessui-state='active selected'],
  &[data-headlessui-state='selected'] {
    background-color: $light-blue;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 spacing(2);
  box-sizing: border-box;
  height: 100%;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      color: $cerulean;
    }

    input {
      width: 100%;
      font-size: $small-font-size;
      font-family: $font-family-regular;
      border: none;
      color: var(--text-color);
      padding: $spacing spacing(2);

      &[data-headlessui-state='disabled'] {
        color: $disabled-grey;
        opacity: 0.35;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
