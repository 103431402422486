@import '../../../styles/variables';
@import '../../../styles/spacing';

.nav {
  display: flex;
  height: 100%;
  align-items: center;

  .tab {
    height: 100%;
    display: flex;
    align-items: center;
    padding: spacing(3) spacing(10);
    font-size: $small-font-size;
    font-family: $font-family-strong;
    color: var(--accent-color);
    display: flex;
    gap: $spacing;

    &.isSelected {
      background-color: $medium-grey;
      color: var(--text-color);
      text-decoration: none;
    }

    &:hover {
      background-color: $light-grey;
    }
  }
}

.savedBarge{
  display: flex;
  flex-direction: column;
}

.errorMessageBox {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    display: flex;
    flex-direction: column;
    padding: spacing(6) spacing(12);
    background-color: var(--primary-color);
    min-height: 4rem;
    font-size: $small-font-size;

    .errorTitle {
      margin: 0;
      font-size: $large-font-size;
    }

    .errorMessage {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        gap: $spacing;
        svg {
          color: $moderate-lime-green;
        }

        &.failed {
          color: $warning-color;
          svg {
            color: $warning-color;
          }
        }
        & + li {
          padding-top: $spacing;
        }
      }
    }
  }
}
