@use 'sass:math';

@mixin round($diameter) {
  height: $diameter;
  width: $diameter;
  border-radius: math.div($diameter, 2);
}

@mixin roundWithBorder($size, $border-size, $border-color) {
  @include round($size);
  border: $border-size $border-color solid;
  box-sizing: border-box;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinner($size, $background-border-color, $foreground-border-color, $border-size: 2px) {
  @include roundWithBorder($size, $border-size, $background-border-color);
  border-bottom-color: $foreground-border-color;
  animation: spinner-rotate 0.75s linear infinite;
}

@mixin clickable {
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: default;
  }
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin input($inputHeight: 35px) {
  width: 100%;
  height: $inputHeight;
  padding: $spacing spacing(2);
  background-color: $white;
  appearance: none;
  outline: none;
  border: none;
  font-family: $font-family-regular;
}
