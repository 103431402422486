@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/spacing.scss';
@import '../../../styles/triangle.scss';

.menu {
  position: relative;
  height: 100%;
  width: 100%;
}

.menuItem {
  @include clickable;
  font-size: $small-font-size;
  padding: spacing(1.5) spacing(2);
  margin-top: spacing(1);

  .prefix {
    font-weight: bold;
    color: $very-dark-blue;
    margin-right: 0.5rem;
  }

  &[data-headlessui-state='active'] {
    background-color: $disabled-grey;
  }

  &.isDisabled {
    background-color: $disabled-grey;
    color: $medium-grey;
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
    margin: spacing(1) 0;
    text-decoration: line-through;
  }
}

.comboboxInput label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-right: auto;
}

.comboboxButton {
  @include clickable;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 100%;
  padding: 0 $xxs-font-size;
  position: relative;
}

.menu {
  position: relative;
  width: 100%;

  font-size: $small-font-size;

  .entryCode {
    font-size: $xs-font-size;
    color: rgba($very-dark-blue, 0.65);
  }

  .comboboxInput {
    label {
      flex-grow: 1;
    }

    .clear {
      @include clickable;
    }
  }

  &Panel {
    max-height: calc(100vh - ($header-height + spacing(6)));
    overflow-y: auto;
  }

  &Header {
    font-family: $font-family-strong;
    padding: spacing(2);
    color: var(--accent-color);
  }

  &Item {
    padding: spacing(2);
    align-items: center;
    gap: $spacing;

    .icon {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }

    .hub {
      background-image: url('/assets/ingram-icon.png');
    }

    .entryCode {
      width: 4.25rem;
      flex-shrink: 0;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
