@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@keyframes spinner-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner {
  opacity: 0;
  display: flex;
  animation: spinner-fade-in 0.75s ease;
  animation-iteration-count: 1;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
  color: $very-dark-blue;
  font-family: $font-family-strong;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    padding-top: 0.25rem;
  }

  &.isFullScreen {
    min-height: 100%;
    justify-content: center;
  }

  &::before {
    content: '';
    @include spinner(1.5rem, $very-dark-blue, rgba($very-dark-blue, 0.3), 0.125rem);
  }
}
